import loadable from '@loadable/component';
import React from 'react';

const DefaultHero = loadable(() => import('./variants/DefaultHero'));
const PricingHero = loadable(() => import('./variants/PricingHero'));
const VerticalHero = loadable(() => import('./variants/VerticalHero'));
const SuccessHeroSpanish = loadable(() =>
  import('./variants/SuccessHeroSpanish')
);
/**
 * Variants:
 * Default Hero
 * Full Width Hero
 * Pricing Hero
 */

const Hero = ({
  breadcrumb,
  component,
  disableLogoNavigation,
  isDark,
  isWithMenu,
  logo,
  nextSectionId,
  slug,
  variant,
}) => {
  if (slug === 'gracias') {
    return (
      <SuccessHeroSpanish
        title={component?.heading}
        body={component?.body.value.document.children.map(i =>
          i.children.map(j => j.value).join(' ')
        )}
        logo={logo}
      />
    );
  }
  switch (variant || component?.heroVariant) {
    case 'default':
      return (
        <DefaultHero
          breadcrumb={breadcrumb}
          component={component}
          disableLogoNavigation={disableLogoNavigation}
          isDark={isDark}
          isWithMenu={isWithMenu}
          logo={logo}
          slug={slug}
        />
      );
    case 'centered-image':
      return (
        <DefaultHero
          breadcrumb={breadcrumb}
          centeredImage
          component={component}
          disableLogoNavigation={disableLogoNavigation}
          isDark={isDark}
          isWithMenu={isWithMenu}
          logo={logo}
          slug={slug}
        />
      );
    case 'vertical':
      return (
        <VerticalHero
          background={component?.background}
          headingBlock={component?.headingBlock}
          headingKickerTag={component?.headingKickerTag}
          headingTag={component?.headingSize}
          nextSectionId={nextSectionId}
          reference={component?.reference}
        />
      );
    case 'full-width':
      // full-width is a special theme that adjusts spacing for no top nav
      // and button colors for the charcoal theme
      return (
        <DefaultHero
          breadcrumb={breadcrumb}
          component={component}
          isDark={isDark}
          isWithMenu={isWithMenu}
          slug={slug}
        />
      );
    case 'pricing':
      return <PricingHero component={component} />;
    default:
      return (
        <DefaultHero
          breadcrumb={breadcrumb}
          component={component}
          isDark={isDark}
          isWithMenu={isWithMenu}
          slug={slug}
        />
      );
  }
};

export default Hero;
